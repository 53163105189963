<template>
  <div class="wrapper -certificaciones padding-menu">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link to="/" style="pointer-events: none;">{{
          $t("navbar_menu.quality_innovation.title")
        }}</router-link>
        <router-link :to="$route.path">{{
          $t("certifications.title")
        }}</router-link>
      </div>
    </div>

    <section class="intro-page pd-0">
      <div class="container">
        <h1>{{ $t("certifications.title") }}</h1>
        <div class="green-box" v-html="$t('certifications.text')"></div>
      </div>

      <div class="contenedor-marcas">
        <div class="marcas-scroll">
          <nav class="row-between container">
            <router-link
              v-for="(brand, key) in $t('certifications.brands')"
              :active="selected == brand.id"
              :key="key"
              :to="'/' + $t('paths.certificaciones') + '/' + brand.id"
            >
              <img
                :src="`/img/empresa-${brand.id}.svg`"
                :alt="`logo ${brand.id}`"
              />
            </router-link>
          </nav>
        </div>

        <div class="textos-certificados">
          <div
            class="container row-between"
            v-for="(brand, key) in $t('certifications.brands')"
            :key="key"
          >
            <template v-if="selected == brand.id">
              <div
                class="left"
                v-for="(facility, idx) in brand.facilities"
                :key="idx"
              >
                <div class="top" v-html="facility.title"></div>
                <div class="bottom row-start">
                  <article
                    v-for="(certification, i) in facility.certifications"
                    :key="i"
                  >
                    <img :src="certification.img" />
                    <div v-html="certification.text"></div>
                  </article>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="container">
          <router-link
            :to="{
              name: 'empresas-grupo',
              params: {
                id:
                  this.$route.params.id == 'comaro'
                    ? 'comar'
                    : this.$route.params.id,
              },
            }"
            class="enlace-flecha"
            style="margin-top: 20px;"
          >
            {{ $t("certifications.btn_text") }}
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "calidad-e-innovacion",
  mounted() {
    if (this.$route.params.id) {
      this.selected = this.$route.params.id;
    }
  },
  data: () => ({
    selected: "ian",
  }),
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.selected = this.$route.params.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-page {
  margin-bottom: 0;
}

.intro-page .container {
  position: relative;

  h2 {
    margin-bottom: 20px;
  }
}

.intro-page .green-box {
  position: absolute;
  left: 0;
  top: 100px;
  max-width: 800px;
  padding: 15px;
  z-index: 3;
  bottom: auto;
}

.intro-page .contenedor-marcas {
  background: url(/img/background-certificaciones.jpg) center/cover no-repeat;
  padding: 75px 0;
  height: auto;
  display: inline-block;

  nav.container {
    position: relative;
    left: auto;
    bottom: auto;
    transform: none;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 900px) {
  .intro-page {
    .green-box {
      left: 0px;
      right: 0px;
      top: 100px;
      margin: 0 auto;
    }
    .contenedor-marcas {
      padding-top: 200px;
    }
    .contenedor-marcas a[active="true"]::before {
      display: none;
    }
    .contenedor-marcas .marcas-scroll {
      overflow-x: scroll;
      padding: 0;
    }
    .textos-certificados {
      margin-top: 0;
    }
    .contenedor-marcas nav.container {
      flex-wrap: nowrap;
      padding: 0;
    }
    .contenedor-marcas a {
      margin: 0;
      padding: 10px;
      width: 25%;
      border-radius: 0;
      &[active="true"] {
        background-color: #ffffff;
        border-radius: 0;
      }
    }
  }
}
</style>
