var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper -certificaciones padding-menu"},[_c('div',{staticClass:"breadcrumb"},[_c('div',{staticClass:"container row-end"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Grupo IAN")]),_c('router-link',{staticStyle:{"pointer-events":"none"},attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("navbar_menu.quality_innovation.title")))]),_c('router-link',{attrs:{"to":_vm.$route.path}},[_vm._v(_vm._s(_vm.$t("certifications.title")))])],1)]),_c('section',{staticClass:"intro-page pd-0"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v(_vm._s(_vm.$t("certifications.title")))]),_c('div',{staticClass:"green-box",domProps:{"innerHTML":_vm._s(_vm.$t('certifications.text'))}})]),_c('div',{staticClass:"contenedor-marcas"},[_c('div',{staticClass:"marcas-scroll"},[_c('nav',{staticClass:"row-between container"},_vm._l((_vm.$t('certifications.brands')),function(brand,key){return _c('router-link',{key:key,attrs:{"active":_vm.selected == brand.id,"to":'/' + _vm.$t('paths.certificaciones') + '/' + brand.id}},[_c('img',{attrs:{"src":`/img/empresa-${brand.id}.svg`,"alt":`logo ${brand.id}`}})])}),1)]),_c('div',{staticClass:"textos-certificados"},_vm._l((_vm.$t('certifications.brands')),function(brand,key){return _c('div',{key:key,staticClass:"container row-between"},[(_vm.selected == brand.id)?_vm._l((brand.facilities),function(facility,idx){return _c('div',{key:idx,staticClass:"left"},[_c('div',{staticClass:"top",domProps:{"innerHTML":_vm._s(facility.title)}}),_c('div',{staticClass:"bottom row-start"},_vm._l((facility.certifications),function(certification,i){return _c('article',{key:i},[_c('img',{attrs:{"src":certification.img}}),_c('div',{domProps:{"innerHTML":_vm._s(certification.text)}})])}),0)])}):_vm._e()],2)}),0),_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"enlace-flecha",staticStyle:{"margin-top":"20px"},attrs:{"to":{
            name: 'empresas-grupo',
            params: {
              id:
                this.$route.params.id == 'comaro'
                  ? 'comar'
                  : this.$route.params.id,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t("certifications.btn_text"))+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }